<template>
  <div class="row align-items-end mb-5">
    <b-form-group label-for="filter-input" class="col m-0">
      <b-input-group>
        <b-form-input
          id="filter-input"
          size="sm"
          v-model="filter"
          type="search"
          debounce="500"
          :placeholder="placeholderText"
          @update="$emit('filter-change', filter)"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "ListSearch",
  props: ["searchName"],
  data() {
    return {
      filter: null
    };
  },
  computed: {
    placeholderText() {
      return `${this.searchName} ${this.$t("ECOMMERCE.COMMON.SEARCH")}`;
    }
  }
};
</script>
